import {RichTextLinkBlockConstants} from '../../adapters/helpers/Constants';
import Block from '../../adapters/helpers/Block';
import dynamic from 'next/dynamic';
const Image = dynamic(() => import('../Image/Image'))
import React from 'react'
import PropTypes from 'prop-types'
function RichTextLink(props) {
    const block = new Block(props);
    const image = block.getFieldValue(RichTextLinkBlockConstants.image);
    const linkType = block.getFieldValue(RichTextLinkBlockConstants.linkType);
    const title = block.getFieldValue(RichTextLinkBlockConstants.title);
    const isPDF = block.getFieldValue(RichTextLinkBlockConstants.isPdfLink);
    const url = isPDF ? block.getFieldValue(RichTextLinkBlockConstants.asset)?.fields?.file?.url  : block.getFieldValue(RichTextLinkBlockConstants.url) 
    const className = block.getFieldValue(RichTextLinkBlockConstants.className) || ''
    const GAclassName = block.getFieldValue(RichTextLinkBlockConstants.GAclassName) || ''
    const dataActionDetail = block.getFieldValue(RichTextLinkBlockConstants.dataActionDetail) || ''
    return (
        <div className={`ob-rich-text-link ${className}`}>
            <a href={url} className={GAclassName} data-action-detail={dataActionDetail}
                target={linkType === 'external' ? '_blank' : null }
            >
                {image ? <Image image={image} /> : <>{title}</>}
            </a>
        </div>
    )
}

RichTextLink.propTypes = {
    /**
    * The Link type (external, internal, social)
    */
    linkType: PropTypes.string,

    /**
     * Image used in the link
     */
    image: PropTypes.object,
    
    /**
     * The url the link points to
     */
    url: PropTypes.string,
}

export default RichTextLink;

